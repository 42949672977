import $ from 'jquery';

export default class{

    constructor(selector)
    {
        this.windowsManager = $(selector);
        $('[windowTarget]').on('click', (event) => this.changeWindow($(event.currentTarget).attr('windowTarget')));
    }

    changeWindow(name)
    {
        this.windowsManager.find('.windows-manager__window--active').removeClass('windows-manager__window--active');
        this.windowsManager.find(`.windows-manager__window[name=${name}]`).addClass('windows-manager__window--active');
    }
}