import $ from 'jquery';
import MemeLib from './MemeLib';
import Uploader from './Uploader';

export default class{

    constructor(selector)
    {
        this.editorForm = $(selector);
        this.uploader = new Uploader('.uploader', (file) => this.setImage(file));
        this.memeLib = new MemeLib('.meme-lib', (file) => this.setImage(file));
        this.editorForm.find('.editor__input').on('input', () => this.updateValues());
        this.updateValues();
    }

    setImage(image)
    {
        const imageReader = new FileReader();
        imageReader.onload = (event) =>  {
            const imageNode = this.editorForm.find('.editor__image');
            imageNode.attr('src', event.target.result).on('load', () => {
                this.image = imageNode;
                this.renderCanvas();
            });
          };
        imageReader.readAsDataURL(image);
    }
    
    updateValues()
    {
        this.fontSize = this.editorForm.find('.editor__input[name=font-size]').val();
        this.textTop = this.editorForm.find('.editor__input[name=text-top]').val();
        this.textBottom = this.editorForm.find('.editor__input[name=text-bottom]').val();
        this.renderCanvas();
    }

    renderCanvas(image)
    {
        
        //Tworzenie płutno
        const canvas = this.editorForm.find('.editor__canvas').get(0);
        const canvasContext = canvas.getContext('2d');

        //Rysuje obrazek tła
        if(this.image == undefined)
            return false;
        canvas.width = this.image.width();
        canvas.height = this.image.height();
        canvasContext.drawImage(this.image.get(0), 0, 0);

        //Rysuje tekst
        canvasContext.font = `${this.fontSize}px Impact`;
        canvasContext.fillStyle = "#fff";
        canvasContext.textAlign = "center";
        canvasContext.fillText(this.textTop, canvas.width/2, canvas.height*0.1);
        canvasContext.fillText(this.textBottom, canvas.width/2, canvas.height*0.9);

        this.generateShareButtons(canvas);
    }

    generateShareButtons(canvas)
    {
        const imageUrl = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
        this.editorForm.find('.editor__button--download').attr('href', imageUrl);
    }
}