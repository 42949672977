import $ from 'jquery';
import WindowsManager from './WindowsManager';
export default class {

    constructor($selector, $uploadCallback) {
        this.form = $($selector);
        this.form.find('.uploader__button').on('click', (event) => this.buttonClick(event));
        this.form.find('.uploader__input').on('change', (event) => this.upload(event));
        this.uploadCallback = $uploadCallback;
        this.windowsManager = new WindowsManager('.windows-manager');
    }

    buttonClick(event)
    {
        event.preventDefault();
        this.form.find('.uploader__input').first().click();
    }

    upload(event)
    {
        this.file = event.currentTarget.files[0];
        this.windowsManager.changeWindow('editor');
        this.uploadCallback(this.file);
    }
}