import $ from 'jquery';
import WindowsManager from './WindowsManager';

export default class{
    
    constructor(selector, callback)
    {
        this.memeLibForm = $(selector).first();
        this.callback = callback;
        this.windowsManager = new WindowsManager('.windows-manager');
        this.memeLibForm.find('.meme-lib__link').on('click', (event) => this.clickLink(event));
    }

    clickLink(event)
    {
        event.preventDefault();
        const imageNode = $(event.currentTarget).find('.meme-lib__image');
        fetch(imageNode.attr('src'))
        .then(resoult => resoult.blob())
        .then(blob => {
          const image = new File([blob], 'kune.jpg', blob);
          this.windowsManager.changeWindow('editor');
          this.callback(image);
        });
    }
}